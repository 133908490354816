import request from "@/utils/request";

// StandardPackage
export function standardPackageList(params) {
  return request({ url: `/standard_packages/`, method: "get", params });
}

export function standardPackageCreate(data) {
  return request({ url: `/standard_packages/`, method: "post", data });
}

export function standardPackageUpdate(data) {
  return request({ url: `/standard_packages/${data.id}/`, method: "put", data });
}

export function standardPackageDestroy(data) {
  return request({ url: `/standard_packages/${data.id}/`, method: "delete" });
}

// MeasurementTask
export function measurementTaskList(params) {
  return request({ url: `/measurement_tasks/`, method: "get", params });
}

export function measurementTaskCreate(data) {
  return request({ url: `/measurement_tasks/`, method: "post", data });
}

export function measurementTaskClose(data) {
  return request({ url: `/measurement_tasks/${data.id}/close/`, method: "post" });
}

export function measurementTaskExport(data) {
  return request({
    url: "/measurement_tasks/export_data/",
    responseType: "blob",
    method: "post",
    data,
  });
}
